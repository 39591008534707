import { useRef, useMemo, useState, useEffect } from 'react';
import { useResetPassword, useSendMagicLinkEmail } from 'api/generated/auth/auth';
import { FaArrowLeft, FaArrowRight, FaEnvelope } from 'react-icons/fa6';
import { PatternTextInput } from 'v2/components/ui/inputs/PatternTextInput/PatternTextInput';
import { DividerWithText } from 'v2/components/shared/dividers/DividerWithText/DividerWithText';
import { Box, Flex, Stack, Image, Loader, Button, Stepper, useMantineTheme, Anchor } from '@mantine/core';
import { PatternPasswordInput } from 'v2/components/ui/inputs/PatternPasswordInput/PatternPasswordInput';

import { Text } from '@/components';

import { NewMemberText } from '../NewMemberText/NewMemberText';
import { useLoginContext } from '../../contexts/LoginContext/LoginContext';
import { MagicLinkContainer } from '../MagicLinkContainer/MagicLinkContainer';

enum LoginSteps {
  EMAIL = 'EMAIL',
  PASSWORD = 'PASSWORD',
  MAGIC_LINK = 'MAGIC_LINK',
  RESET_PASSWORD = 'RESET_PASSWORD',
}

interface LoginFormProps {
  layout: 'MOBILE' | 'TABLET' | 'DESKTOP';
}

export const LoginForm = ({ layout }: LoginFormProps) => {
  const theme = useMantineTheme();
  const { form, submit, isSubmitting } = useLoginContext();

  const { mutate } = useSendMagicLinkEmail();
  const { mutate: callResetPass } = useResetPassword();

  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);

  const [activeStep, setActiveStep] = useState(LoginSteps.EMAIL);

  useEffect(() => {
    if (activeStep === LoginSteps.EMAIL && emailInputRef.current) {
      emailInputRef.current.focus();
    }
    if (activeStep === LoginSteps.PASSWORD && passwordInputRef.current) {
      passwordInputRef.current.focus();
    }
  }, [activeStep]);

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (activeStep === LoginSteps.EMAIL) {
        nextStep();
      } else if (activeStep === LoginSteps.PASSWORD) {
        submit();
      }
    }
  };

  const nextStep = () => {
    switch (activeStep) {
      case LoginSteps.EMAIL: {
        const { hasError } = form.validateField('email');

        if (!hasError) {
          setActiveStep(LoginSteps.PASSWORD);
        }

        break;
      }
      case LoginSteps.PASSWORD:
        setActiveStep(LoginSteps.MAGIC_LINK);
        mutate({ data: { email: form.values.email } });
        break;
      default:
        break;
    }
  };

  const prevStep = () => {
    switch (activeStep) {
      case LoginSteps.PASSWORD:
        setActiveStep(LoginSteps.EMAIL);
        break;
      case LoginSteps.RESET_PASSWORD:
      case LoginSteps.MAGIC_LINK:
        setActiveStep(LoginSteps.PASSWORD);
        break;
      default:
        break;
    }
  };

  const title = useMemo(() => {
    switch (activeStep) {
      case LoginSteps.EMAIL:
        return 'Sign in to Blink';
      case LoginSteps.PASSWORD:
        return 'Sign in with password';
      case LoginSteps.MAGIC_LINK:
        return 'Use a magic link';
      default:
        return '';
    }
  }, [activeStep]);

  const isMobile = layout === 'MOBILE';

  const resetPassword = () => {
    callResetPass({ data: { email: form.values.email } });
    setActiveStep(LoginSteps.RESET_PASSWORD);
  };

  return (
    <Stack w="100%" h="100vh" align="center" justify="space-between">
      <Stack w="100%" align="flex-start" justify="center">
        <Flex
          mt={35}
          w="100%"
          sx={{
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <Button
            variant="subtle"
            onClick={prevStep}
            leftIcon={<FaArrowLeft />}
            hidden={activeStep === LoginSteps.EMAIL}
          >
            Back
          </Button>
        </Flex>
      </Stack>
      <Stack w="100%" mt={isMobile ? 75 : 0}>
        <Stack w="100%" align="center" justify="center">
          <Image alt="Blink" radius="md" width="145px" src="/images/blink-logo-black.png" />
          <Text mt={5} fw="bold" color="dimmed" align="center" sx={{ fontSize: '20pt' }}>
            Welcome to Blink Materials
          </Text>
        </Stack>

        <Text mt={35} fw="500" color="dark" align="center" sx={{ fontSize: '16pt' }}>
          {title}
        </Text>

        <Flex w="100%" align="center" justify="center">
          <Stepper
            w="100%"
            active={Object.values(LoginSteps).indexOf(activeStep)}
            styles={{
              stepIcon: { display: 'none' },
              separator: { display: 'none' },
              stepLabel: { display: 'none' },
              stepDescription: { display: 'none' },
            }}
          >
            <Stepper.Step w="100%" aria-label="Email Section">
              <Stack
                spacing={25}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <PatternTextInput
                  ref={emailInputRef}
                  {...form.getInputProps('email')}
                  w="100%"
                  required
                  label="Email"
                  placeholder="Your email"
                  onKeyDown={handleKeyPress}
                  data-testid="login-form-email"
                />
                <Button
                  fullWidth
                  maw="75%"
                  onClick={nextStep}
                  disabled={!form.values.email}
                  rightIcon={<FaArrowRight size={18} />}
                  data-testid="login-form-first-step-button"
                  style={{
                    height: '45px',
                    color: 'white',
                    display: 'flex',
                    fontSize: '16px',
                    borderRadius: '5px',
                    padding: '8px 16px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  Continue
                </Button>
              </Stack>
            </Stepper.Step>
            <Stepper.Step aria-label="Password Section">
              <Stack
                spacing={25}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <PatternPasswordInput
                  ref={passwordInputRef}
                  {...form.getInputProps('password')}
                  w="100%"
                  required
                  label="Password"
                  onKeyDown={handleKeyPress}
                  placeholder="Your password"
                  data-testid="login-form-password"
                />
                <Button
                  fullWidth
                  maw="75%"
                  onClick={submit}
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  data-testid="login-form-sign-in-button"
                  style={{
                    height: '45px',
                    color: 'white',
                    display: 'flex',
                    fontSize: '16px',
                    borderRadius: '5px',
                    padding: '8px 16px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {isSubmitting ? <Loader size="sm" /> : 'Sign in'}
                </Button>
                <Anchor
                  key="forgot-password"
                  onClick={resetPassword}
                  sx={(theme) => ({ fontWeight: 600, color: theme.fn.primaryColor() })}
                >
                  Reset my password
                </Anchor>
                <Stack mt={45} spacing={45}>
                  <DividerWithText text="OR" />
                  <MagicLinkContainer onClickProps={nextStep} />
                </Stack>
              </Stack>
            </Stepper.Step>
            <Stepper.Step aria-label="Magic Link Section">
              <Stack
                mt={45}
                spacing={15}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    width: '86px',
                    height: '86px',
                    color: 'white',
                    display: 'flex',
                    borderRadius: '50%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: theme.colors.blue[9],
                  }}
                >
                  <FaEnvelope size={24} />
                </Box>
                <Text fw="bolder" color="dark" align="center" sx={{ fontSize: '20pt' }}>
                  Please check your email
                </Text>
                <Text mt={15} fw="300" color="gray" align="center" sx={{ fontSize: '16pt', lineHeight: '30px' }}>
                  We sent you an email to{' '}
                  <span style={{ fontWeight: 'bolder', color: 'black' }}>{form.values.email}</span>. In the email you
                  will find a magic link to log in to the Blink platform.
                </Text>
                <Text mt={15} fw="300" color="gray" align="center" sx={{ fontSize: '14pt' }}>
                  The link expires in 24 hours.
                </Text>
              </Stack>
            </Stepper.Step>
            <Stepper.Step aria-label="Reset Password Section">
              <Stack
                mt={45}
                spacing={15}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    width: '86px',
                    height: '86px',
                    color: 'white',
                    display: 'flex',
                    borderRadius: '50%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: theme.colors.blue[9],
                  }}
                >
                  <FaEnvelope size={24} />
                </Box>
                <Text fw="bolder" color="dark" align="center" sx={{ fontSize: '20pt' }}>
                  Please check your email
                </Text>
                <Text mt={15} fw="300" color="gray" align="center" sx={{ fontSize: '16pt', lineHeight: '30px' }}>
                  We sent you an email to{' '}
                  <span style={{ fontWeight: 'bolder', color: 'black' }}>{form.values.email}</span>. In the email you
                  will find your new password to access Blink platform.
                </Text>
              </Stack>
            </Stepper.Step>
          </Stepper>
        </Flex>
      </Stack>
      <Stack pb={35} w="100%" align="center" justify="center">
        <NewMemberText />
      </Stack>
    </Stack>
  );
};
