/* eslint-disable react/display-name */
import { forwardRef } from 'react';
import { TextInput, TextInputProps } from '@mantine/core';

import { useContainedLabelStyles } from '@/common/use-contained-label-styles';

export type PatternTextInputProps = Omit<TextInputProps, 'classNames'>;

export const PatternTextInput = forwardRef<HTMLInputElement, PatternTextInputProps>((props, ref) => {
  const { classes: containedLabel } = useContainedLabelStyles();

  return <TextInput {...props} ref={ref} classNames={containedLabel} />;
});
