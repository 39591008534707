/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Blink API
 * The Blink API description
 * OpenAPI spec version: 1.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import type { UseMutationOptions, MutationFunction } from '@tanstack/react-query';

import type { SendMagicLinkEmailRequestDto, ResetPasswordRequestDto } from '.././model';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const sendMagicLinkEmail = (
  sendMagicLinkEmailRequestDto: SendMagicLinkEmailRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/v2/auth/send-magiclink-email`, sendMagicLinkEmailRequestDto, options);
};

export type SendMagicLinkEmailMutationResult = NonNullable<Awaited<ReturnType<typeof sendMagicLinkEmail>>>;
export type SendMagicLinkEmailMutationBody = SendMagicLinkEmailRequestDto;
export type SendMagicLinkEmailMutationError = AxiosError<unknown>;

export const useSendMagicLinkEmail = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendMagicLinkEmail>>,
    TError,
    { data: SendMagicLinkEmailRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendMagicLinkEmail>>,
    { data: SendMagicLinkEmailRequestDto }
  > = (props) => {
    const { data } = props ?? {};

    return sendMagicLinkEmail(data, axiosOptions);
  };

  return useMutation<
    Awaited<ReturnType<typeof sendMagicLinkEmail>>,
    TError,
    { data: SendMagicLinkEmailRequestDto },
    TContext
  >(mutationFn, mutationOptions);
};
export const resetPassword = (
  resetPasswordRequestDto: ResetPasswordRequestDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<void>> => {
  return axios.post(`/api/v2/auth/reset-password`, resetPasswordRequestDto, options);
};

export type ResetPasswordMutationResult = NonNullable<Awaited<ReturnType<typeof resetPassword>>>;
export type ResetPasswordMutationBody = ResetPasswordRequestDto;
export type ResetPasswordMutationError = AxiosError<unknown>;

export const useResetPassword = <TError = AxiosError<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetPassword>>,
    TError,
    { data: ResetPasswordRequestDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof resetPassword>>, { data: ResetPasswordRequestDto }> = (
    props
  ) => {
    const { data } = props ?? {};

    return resetPassword(data, axiosOptions);
  };

  return useMutation<Awaited<ReturnType<typeof resetPassword>>, TError, { data: ResetPasswordRequestDto }, TContext>(
    mutationFn,
    mutationOptions
  );
};
