/* eslint-disable react/display-name */
import { ActionIcon } from '@mantine/core';
import { forwardRef, useState } from 'react';
import { IoEyeOff, IoEyeSharp } from 'react-icons/io5';

import { PatternTextInput, PatternTextInputProps } from '../PatternTextInput/PatternTextInput';

type PatternPasswordInputProps = Omit<PatternTextInputProps, 'type'>;

export const PatternPasswordInput = forwardRef<HTMLInputElement, PatternPasswordInputProps>((props, ref) => {
  const [visible, setVisible] = useState(false);

  const toggleVisibility = () => setVisible((visibility) => !visibility);

  return (
    <PatternTextInput
      ref={ref}
      {...props}
      type={visible ? 'text' : 'password'}
      rightSection={
        <ActionIcon mt={-10} size="lg" onClick={toggleVisibility}>
          {visible ? <IoEyeOff size={18} /> : <IoEyeSharp size={18} />}
        </ActionIcon>
      }
    />
  );
});
