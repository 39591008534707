import { useEffect } from 'react';
import { getCsrfToken } from 'next-auth/react';
import { useMantineTheme } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { cleanUrlSearch } from 'v2/features/login/utils/clean-url-search';
import type { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next';
import { LoginProvider } from 'v2/features/login/contexts/LoginContext/LoginContext';
import { LoginMobileLayout } from 'v2/features/login/layout/LoginMobileLayout/LoginMobileLayout';
import { LoginTabletLayout } from 'v2/features/login/layout/LoginTabletLayout/LoginTabletLayout';
import { LoginDesktopLayout } from 'v2/features/login/layout/LoginDesktopLayout/LoginDesktopLayout';

import { useWindowSize } from '@/core/hooks/use-window-size';

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const csrfToken = await getCsrfToken(context);

  const { error } = context.query;

  return {
    props: {
      csrfToken,
      error: error || null,
    },
  };
}

export default function SignIn({ csrfToken, error }: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const theme = useMantineTheme();
  const { width } = useWindowSize();

  const isMobile = width <= theme.breakpoints.sm;
  const isTablet = width > theme.breakpoints.sm && width <= theme.breakpoints.lg;
  const isDesktop = width > theme.breakpoints.lg;

  useEffect(() => {
    if (error) {
      let errorMessage;

      switch (error) {
        case 'CredentialsSignin':
          errorMessage = 'Email or password was incorrect. Please try again.';
          break;
        default:
          errorMessage = 'There was an error while trying to log in. Please try again.';
          break;
      }

      showNotification({
        color: 'red',
        autoClose: 5000,
        message: errorMessage,
      });

      cleanUrlSearch();
    }
  }, [error]);

  return (
    <LoginProvider>
      {isMobile && <LoginMobileLayout />}
      {isTablet && <LoginTabletLayout />}
      {isDesktop && <LoginDesktopLayout />}
    </LoginProvider>
  );
}
