import { signIn } from 'next-auth/react';
import { useState, ReactNode, useContext, createContext } from 'react';
import { useForm, UseFormReturnType, zodResolver } from '@mantine/form';

import { formSchema, FormValues, INITIAL_VALUES } from '../../schemas';

interface LoginContextType {
  isSubmitting: boolean;
  submit: () => Promise<void>;
  form: UseFormReturnType<FormValues>;
}

const LoginContext = createContext<LoginContextType | undefined>(undefined);

interface LoginProviderProps {
  children: ReactNode;
}

export const LoginProvider = ({ children }: LoginProviderProps) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const form = useForm<FormValues>({
    initialValues: INITIAL_VALUES,
    validate: zodResolver(formSchema),
  });

  const submit = async () => {
    const errors = form.validate();

    if (!errors.hasErrors) {
      setIsSubmitting(true);

      const { email, password } = form.values;

      await signIn('credentials', {
        password,
        redirect: true,
        username: email,
        callbackUrl: '/app',
      });
    }
  };

  return <LoginContext.Provider value={{ form, submit, isSubmitting }}>{children}</LoginContext.Provider>;
};

export const useLoginContext = () => {
  return useContext(LoginContext);
};
