import { Box, Grid } from '@mantine/core';

import { LoginForm } from '../../components/LoginForm/LoginForm';

export const LoginTabletLayout = () => {
  return (
    <Grid m={0} w="100vw" h="100vh">
      <Grid.Col xs={5}>
        <Box w="75%">
          <LoginForm layout="TABLET" />
        </Box>
      </Grid.Col>
      <Grid.Col
        xs={7}
        sx={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: 'url(/images/banners/login-banner.png)',
        }}
      />
    </Grid>
  );
};
