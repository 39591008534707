import { z } from 'zod';

export const formSchema = z.object({
  email: z.string().email({ message: 'Invalid email address' }),
  password: z.string().nonempty({ message: 'Password is required' }),
});

export type FormValues = z.infer<typeof formSchema>;

export const INITIAL_VALUES: FormValues = {
  email: '',
  password: '',
};
