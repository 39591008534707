import { Image, Stack, Button, createStyles } from '@mantine/core';

import { Text } from '@/components';

interface MagicLinkContainerProps {
  onClickProps: VoidFunction;
}

const useStyles = createStyles((theme) => ({
  button: {
    height: '45px',
    color: 'black',
    display: 'flex',
    fontSize: '16px',
    borderRadius: '5px',
    padding: '8px 16px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.gray[4],
    '&:hover': {
      backgroundColor: theme.fn.darken(theme.colors.gray[4], 0.1), // Darken by 10%
    },
  },
}));

export const MagicLinkContainer = ({ onClickProps }: MagicLinkContainerProps) => {
  const { classes } = useStyles();

  return (
    <Stack align="center" justify="center">
      <Text mt={5} fw="bold" color="dimmed" align="center" sx={{ fontSize: '16pt' }}>
        Sign in with link
      </Text>
      <Text mt={5} color="dimmed" align="center" sx={{ fontSize: '14pt' }}>
        We can send you a magic link to your email so you can sign in without having to type your password
      </Text>
      <Button
        mt={25}
        fullWidth
        maw="75%"
        color="gray"
        onClick={onClickProps}
        className={classes.button}
        data-testid="login-form-magic-link-button"
        leftIcon={<Image mt={-2} color="black" alt="Magic Link Icon" src="/images/icons/MagicLinkPointer.svg" />}
      >
        Send me a magic link
      </Button>
    </Stack>
  );
};
