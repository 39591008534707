import { Anchor } from '@mantine/core';

import { Text } from '@/components';
import { MAIL_TO_CONTACT_URL } from '@/common/consts';

export const NewMemberText = () => {
  return (
    <Text color="gray.8" size="M" mt={104}>
      {`New Member? `}
      <Anchor
        key="link"
        href={MAIL_TO_CONTACT_URL}
        sx={(theme) => ({ fontWeight: 600, color: theme.fn.primaryColor() })}
      >
        Contact us!
      </Anchor>
    </Text>
  );
};
