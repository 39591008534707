import { Grid, Stack } from '@mantine/core';

import { LoginForm } from '../../components/LoginForm/LoginForm';

export const LoginMobileLayout = () => {
  return (
    <Grid m={0} w="100vw" h="100vh">
      <Grid.Col
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Stack w="85%" align="center" justify="center">
          <LoginForm layout="MOBILE" />
        </Stack>
      </Grid.Col>
    </Grid>
  );
};
