import { Box, Grid } from '@mantine/core';

import { LoginForm } from '../../components/LoginForm/LoginForm';

export const LoginDesktopLayout = () => {
  return (
    <Grid m={0} w="100vw" h="100vh">
      <Grid.Col
        xs={4}
        sx={{
          display: 'flex',
          height: '100vh',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Box w="75%">
          <LoginForm layout="DESKTOP" />
        </Box>
      </Grid.Col>
      <Grid.Col
        xs={8}
        sx={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: 'url(/images/banners/login-banner.png)',
        }}
      />
    </Grid>
  );
};
