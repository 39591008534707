import { useMantineTheme } from '@mantine/core';
import { Group, Center, Divider } from '@mantine/core';

import { Text } from '@/components';

interface DividerWithTextProps {
  text: string;
}

export const DividerWithText = ({ text }: DividerWithTextProps) => {
  const theme = useMantineTheme();

  return (
    <Center style={{ width: '100%', height: '20px' }}>
      <Group style={{ width: '100%', alignItems: 'center', height: '20px' }}>
        <Divider style={{ flexGrow: 1, borderColor: theme.colors.gray[5] }} />
        <Text color="dimmed" size="XS" px="xs" style={{ whiteSpace: 'nowrap' }}>
          {text}
        </Text>
        <Divider style={{ flexGrow: 1, borderColor: theme.colors.gray[5] }} />
      </Group>
    </Center>
  );
};
